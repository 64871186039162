<template>
  <div class="container">
    <div>
      <h4>
        欢迎来到CTRN43062的淫饼视频搜索站，请在上方输入你想要的搜索的数据。
      </h4>
      <br /><br />
      <p>FAQ:</p>
      <p>那些视频能被搜索到?</p>
      <p>答: 大部分B站内的银饼相关的视频（心虚）。</p>
      <p>更多信息见这篇<a href="https://www.bilibili.com/read/cv11202788" target="_blank">专栏</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultPage",
};
</script>

<style lang="less" scoped>
.container {
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ccc;
}
</style>